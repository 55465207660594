import { render, staticRenderFns } from "./case_qbdetails.vue?vue&type=template&id=14c37e86&scoped=true"
import script from "./case_qbdetails.vue?vue&type=script&lang=js"
export * from "./case_qbdetails.vue?vue&type=script&lang=js"
import style0 from "./case_qbdetails.vue?vue&type=style&index=0&id=14c37e86&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14c37e86",
  null
  
)

export default component.exports
<template>
  <div class="search">
    <!-- 上搜索背景 -->
    <div class="sousuo-beijing">
        <div class="sousuo-beijing-kuang">
            <el-input v-model="input" placeholder="搜索规则关键词，如：营销"></el-input>
            <img @click="sousuo" style="width: 16px;height: 16px;" src="../assets/image/sousuochakan.png"/>
        </div>
    </div>
    <div class="case-list">
       <div class="case-list-guizezhongxin">
          <div class="case-guizezhongxin-daohang">
            <div class="caidan-kuan" :key="nemukey">
              <el-menu  class="el-menu-vertical-demo" :default-active="defact" @open="handleOpen" @close="handleClose" @select="menuchange">
                <template v-for="(item) in typeundex">
                  <el-submenu :index='item.id'  v-show="item.child.length != 0">
                    <template slot="title">
                        <span>{{item.title}}</span>
                    </template>

                    <el-menu-item v-for="(sitem,sindex) in item.child" :index='sitem.id' v-show="sitem.child.length == 0" :key="sindex">
                      <template slot="title">
                          <span>{{sitem.title}}</span>
                      </template>
                    </el-menu-item>

                    <el-submenu v-for="(sitem,sindex) in item.child" :index='sitem.id' v-show="sitem.child.length != 0" :key="sindex">
                      <template slot="title">
                          <span>{{sitem.title}}</span>
                      </template>

                      <el-menu-item v-for="(ssitem,ssindex) in sitem.child" :index='ssitem.id' :key="ssindex">
                        <template slot="title">
                            <span>{{ssitem.title}}</span>
                        </template>
                      </el-menu-item>
                    </el-submenu>
                  </el-submenu>
                  <el-menu-item v-show="item.child.length == 0" :index='item.id'>
                    <template slot="title">
                        <span>{{item.title}}</span>
                    </template>
                  </el-menu-item>
                </template>
              </el-menu>
             
            </div>
          </div>
          <div class="case-guizezhongxin-rightcenter">
            <!-- 选择的标题 -->
              <div class="case-xuan-title">
                <div v-for="(item,index) in getfrounames(pageid)">
                  <span :class="{'act':index == getfrounames(pageid).length - 1}">{{ item }}</span>
                  <span v-if="index != getfrounames(pageid).length - 1">></span>
                </div>
              </div>
              <!-- 全部列表 -->  
              <casepitonglist  v-if="pagetype == 1" :id="pageid" @listid="listid" :sousuocenter="input" :key="pagekey"></casepitonglist>
              <!-- 全部列表详情 -->
              <caseqbdetails v-if="pagetype == 2" :id="lsid"  :key="pagekey"></caseqbdetails>
              <!-- 议事厅展示列表 -->
              <caseyishitinglist v-if="pagetype == 3" :sousuocenter="input" :id="pageid" :key="pagekey"></caseyishitinglist>
          </div>
       </div>
    </div>
  </div>
</template>


<script>
import casepitonglist from '../views/casepitonglist.vue'
import caseyishitinglist from '../views/caseyishitinglist.vue';
import caseqbdetails from '../views/case_qbdetails.vue';
  export default {
    name:"case",
    components: { casepitonglist,caseyishitinglist,caseqbdetails},
    data () {
      return {
        input:'',
        mocten:[],
        show:false,
        typelist:{
          id:'0'
        },
        defact:0,
        typeundex:[],

        pagetype:1, 
        pageid:'0',
        lsid:'',
        pagekey:0,
        detailid:"",
        levelList:null,
        nemukey:911,
      }
    },
    mounted () {
        this.fenlei();
        this.detailid=this.$route.query.id;
    },
    methods: {
      //追溯路由
      getfrounames(id){
        if(this.typeundex.length == 0 ){
          return ''
        }
        let frouname = []
        this.findParent(this.typeundex,id,frouname)
        frouname.unshift('规则目录')
        return frouname
      },

      //递归
      findParent(data, target, result) {
        for (let i in data) {
          let item = data[i]
          if (item.id == target) {
            result.unshift(item.title)
            return true
          }
          if (item.child && item.child.length > 0) {
            let ok = this.findParent(item.child, target, result)
            if (ok) {
              result.unshift(item.title)
              return true
            }
          }
        }
        return false
      },
      listid(e){
        this.lsid = e.typeid
        this.pagetype = 2
      },
      // page(){
      //   this.lsid = this.detailid
      //   this.pagetype = 2 
      // },
      // 搜索
      sousuo(){
        this.pageid = 0
        this.pagetype = 1
        this.defact = 0
        this.nemukey +=1
        this.pagekey += 1
        // this.fenlei() 
      },
      // 规则中心分类
      fenlei(){
        let that = this
        that.$http({
          url: that.baseurl +"/getRuleCenterIndex?id="+ that.typelist.id,
          method: "get",
          headers:{
           "web-token":localStorage.getItem('auto_token')
          },
          data:{}
        })
          .then(res => {
              if(res.data.code==200){

                  res.data.data.unshift({
                    child:[],
                    id:0,
                    pid:0,
                    sort:0,
                    title:'全部'
                  })
                  this.typeundex = res.data.data;
                  // that.$message.success(res.data.msg);
              }else{
                  that.$message.error(res.data.msg);
                  // that.$router.push('/')
              }
              
          })
          .catch(function(error) {
              //  that.$message.error(res.data.message);
        });
      },
      handleOpen(e) {
        console.log(key, keyPath);
      },
      handleClose(key, keyPath) {
        console.log(key, keyPath);
      },

      menuchange(e){
        this.pageid = e
        this.input = ''
        // this.getBreadcrumb()
        if(this.pageid == 2){
          ///议事厅
          this.pagetype = 3
        }else{
          this.pagetype = 1
        }
         this.pagekey += 1
      }
    },
  }

</script>
<style scoped>
  .search{
    width: 100%;
    background-color: #F2F5FB ;
  }
  .sousuo-beijing{
    width: 100%;
    height: 418px;
    background: url('../assets/image/sousuobeiijingtu.png') no-repeat; 
    background-size: cover;
    position: relative;
  }
  .sousuo-beijing-kuang{
    position: absolute;
    top:50%;
    left:40% ;
    width: 570px;
    height: 56px;
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #DCDFE6;
    padding: 0 20px;
  }

  .case-list{
    width: 100%;
    max-width: 1520px;
    /* height: 500px; */
    margin: 0px auto;
    padding-bottom: 20px;
  }
  .case-list-guizezhongxin{
    width: 100%;
    display: flex;
    justify-content: space-between;
     margin-top: 40px;
  }
  .case-guizezhongxin-daohang{
    width: 18%;
    /* min-height: 440px; */
    background-color: #fff;
    box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.03);
    border-radius: 8px;
    border: 0px solid #979797;
  }
  /* .caidan-kuan{
    width: 90%;
    margin: 0 auto;
  } */
  .case-guizezhongxin-rightcenter{
    width: 80%;
    /* min-height: 1034px; */
    height: 1034px;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.03);
    border-radius: 8px;
    border: 0px solid #979797;
    overflow-y: scroll;
  }
  .case-xuan-title{
    padding: 24px;
    display: flex;
    align-items: center;
    color: #999;
    font-size: 14px;
  }
  .case-xuan-title .act{
    color: #333;
  }
  .case-list-zhanshi{
    width: 96%;
    margin: 15px auto;
    height: 166px;
    background: #FFFFFF;
    box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.03);
    border-radius: 8px;
    border: 1px solid #F1F1F1;
  }
  .case-zhanshi-leibiao{
    width: 92%;
    margin-left: 4%;
    margin-right: 4%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .case-zhanshi-leibiao-title{
    width: 80%;
    margin-top: 24px;
  }
  .case-riqi{
    font-size: 14px;
    color: #999999;
  }
  .case-riqi-erwei{
    font-weight: 550;
    font-size: 16px;
    color: #333333;
    padding: 24px 0 24px 0;
  }
  .case-zhanshi-leibiao-imgzhan{
    width: 17%;
    margin-top: 24px;
  }
  .case-zhanshi-leibiao-imgzhan img{
    width: 180px;
    height: 118px;
    border-radius: 8px;
  }
  .chakanren-list{
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #999999;
  }
  .case-quanbu-list{
    width: 92%;
    margin-left: 4%;
    margin-right: 4%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #EEEEEE ;
    padding: 25px 0;
    position: relative;
  }
  .quanbu-list-mingzi{
    font-weight: 550;
    font-size: 16px;
    color: #333333;
  }
  .quanbu-list-mingzi::after{
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color:#275AF5;
    opacity: 0.5;
    position: absolute;
    left: -12px;
    top: 35px;
  }
  .riqi-quanbu{
    font-size: 14px;
    color: #999999;
  }
</style>
<style >
.sousuo-beijing-kuang .el-input__inner{
    width: 85%;
    height: 56px !important;
    border-radius: 8px !important;
    border: none !important;
    font-size: 18px;
    font-weight: 550;
    color: #333333 ;
}
.case-guizezhongxin-daohang .el-menu-item {
  font-size: 14px !important;
  color: #333333 !important;
  font-weight: 550 !important;
}
.case-guizezhongxin-daohang .el-submenu__title{
  font-size: 14px !important;
  color: #333333 !important;
  font-weight: 550 !important;
}
.case-guizezhongxin-daohang .el-submenu__title{
  padding: 0;
}
.case-guizezhongxin-daohang .el-menu-item.is-active {
    font-size: 14px !important;
    font-weight: 550 !important;
    color: #275AF5 !important;
}
.case-guizezhongxin-daohang .el-menu-item.is-activer{
  background: rgba(39, 90, 245, 0.08);
}

/* 定义滚动条轨道 */
::-webkit-scrollbar {
  width: 6px; /* 设置滚动条宽度 */
}

/* 定义滚动条的轨道背景 */
::-webkit-scrollbar-track {
  background: transparent; /* 设置滚动条轨道背景颜色 */
}

/* 定义滚动条滑块 */
::-webkit-scrollbar-thumb {
  background: #F1F1F1 ; /* 设置滚动条滑块颜色 */
  border-radius: 3px;
 
}
/* 定义滚动条滑块悬停时的样式 */
::-webkit-scrollbar-thumb:hover {
  background: #dcdcdc; /* 设置滚动条滑块悬停时的颜色 */
}
/* .el-menu-item{
   max-width: 270px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
} */
</style>

<template>
  <div class="search">
    <!-- 上搜索背景 -->
    <div class="case-list">
        <!-- 除议事厅展示列表 -->
        <div class="case-list-zhanshi" v-for="(item,index) in datalist" :key="index">
            <div class="case-zhanshi-leibiao" @click="toyishiting(item)">
                <div class="case-zhanshi-leibiao-title">
                    <div class="case-riqi">{{item.send_time}}</div>
                    <div class="case-riqi-erwei">{{item.title}}</div>
                    <div class="chakanren-list">
                      <div><img style="width:14px;height: 14px;margin-right: 8px;" src="../assets/image/huiyan.png"/></div>
                      <div>{{item.browse_number}}</div>
                    </div>
                </div>
                <div class="case-zhanshi-leibiao-imgzhan">
                    <img :src="item.img"/>
                </div>
            </div>
        </div>
        <div class="qurshengya-list" v-if="datalist.length==0">
          <img style=" width: 417px; height: 365px;" src="../assets/image/wushujusousuo.png"/>
          <div class="weisousuo">未搜索到相关结果</div>
          <div class="jianyixiugai">建议您修改搜索关键词后重新再试～</div>
        </div>
    </div>
  </div>
</template>

<script>
  export default {
    name:"casepitonglist",
     props:{
      id:{
        type: Number,
			  default: '',
      },
      sousuocenter:{
        type:String,
        default: '',
      }
    },
    data () {
      return {
        input:'',
        mocten:[],
        show:false,
        datalist:[],
         listQuery: {
          PageNo: 1,
          PageSize: 10,
        },
      }
    },
    mounted () {
      if(this.sousuocenter != ''){
        this.sosuo()
     }else{
        this.suoyoulist()
     }
    },
    methods: {
      sosuo(){
          let that = this
        that.$http({
          url: that.baseurl +"/searchRule?keyword="+ that.sousuocenter,
          method: "get",
          headers:{
           "web-token":localStorage.getItem('auto_token')
          },
          data:{}
        })
          .then(res => {
              if(res.data.code==200){
                  // that.$message.success(res.data.msg);
                  // that.suoyoulist()
                  that.datalist = res.data.data;
              }else{
                  that.$message.error(res.data.msg);
                  // that.$router.push('/')
              }
              
          })
          .catch(function(error) {
              //  that.$message.error(res.data.message);
        });
      },
      suoyoulist(){
         let that = this
        that.$http({
          url: that.baseurl +"/getRuleIndex?rule_center_id="+ that.id +'&page='+that.listQuery.PageNo +'&limit='+that.listQuery.PageSize,
          method: "get",
          headers:{
           "web-token":localStorage.getItem('auto_token')
          },
          data:{}
        })
          .then(res => {
              if(res.data.code==200){
                  this.datalist = res.data.data.data;
                  // that.$message.success(res.data.msg);
              }else{
                  that.$message.error(res.data.msg);
                  // that.$router.push('/')
              }
              
          })
          .catch(function(error) {
              //  that.$message.error(res.data.message);
        });
      },
      handleOpen(key, keyPath) {
        console.log(key, keyPath);
      },
      handleClose(key, keyPath) {
        console.log(key, keyPath);
      },
      toyishiting(data){
        this.$router.push('/caseyushutdetails?id='+data.id)
      }
    },
  }

</script>
<style scoped>
  .search{
    width: 100%;
    /* background-color: #F2F5FB ; */
  }
 
  .case-list{
    width: 100%;
     background-color: #fff;
  }
   .case-list-zhanshi{
    width: 96%;
    margin: 15px auto;
    height: 166px;
    background: #FFFFFF;
    box-shadow: 0px 2px 20px 0px rgba(0,0,0,0.03);
    border-radius: 8px;
    border: 1px solid #F1F1F1;
  }
  .case-zhanshi-leibiao{
    width: 92%;
    margin-left: 4%;
    margin-right: 4%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .case-zhanshi-leibiao-title{
    width: 80%;
    margin-top: 24px;
  }
  .case-riqi{
    font-size: 14px;
    color: #999999;
  }
   .case-riqi-erwei{
    font-weight: 550;
    font-size: 16px;
    color: #333333;
    padding: 24px 0 24px 0;
  }
  .case-zhanshi-leibiao-imgzhan{
    width: 17%;
    margin-top: 24px;
    background-color:  #d3dce6;
  }
  .case-zhanshi-leibiao-imgzhan img{
    width: 180px;
    height: 118px;
    border-radius: 8px;
  }
  .chakanren-list{
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #999999;
  }
  .qurshengya-list{
    text-align: center;
    margin-top: 260px;
  }
  .weisousuo{
    font-weight: 500;
    font-size: 18px;
    color: #333333;
    padding: 15px 0;
  }
  .jianyixiugai{
    font-weight: 400;
    font-size: 14px;
    color: #999999;
  }
</style>



<template>
  <div class="search">
    <!-- 上搜索背景 -->
    <div class="case-list">
        <!-- 除议事厅展示列表 -->
        <div class="case-quanbu-list" v-for="(item,index) in datalist" :key="index">
            <div class="quanbu-list-mingzi"  @click="item.rule_center_id==2?toyishiting(item):toage(item)">{{item.title}}</div>
            <div class="riqi-quanbu">{{item.send_time}}</div>
        </div> 
        <div class="qurshengya-list" v-if="datalist.length==0">
          <img style=" width: 417px; height: 365px;" src="../assets/image/wushujusousuo.png"/>
          <div class="weisousuo">未搜索到相关结果</div>
          <div class="jianyixiugai">建议您修改搜索关键词后重新再试～</div>
        </div>
    </div>
  </div>
</template>

<script>
  export default {
    name:"casepitonglist",
    props:{
      id:{
        type: Number,
			  default: '',
      },
      sousuocenter:{
        type:String,
        default: '',
      }
    },
    data () {
      return {
        input:'',
        mocten:[],
        show:false,
         listQuery: {
          PageNo: 1,
          PageSize: 999999,
        },
        datalist:[]
      }
    },
    mounted () {
      console.log(this.id,'!!!')
     //获取接口
     if(this.sousuocenter != ''){
        this.sosuo()
     }else{
        this.suoyoulist()
     }
     
    },
    methods: {
      toage(data){
        //this.$router.push('/infodetails')
        console.log(data.id,'$$$$')
        this.$emit('listid',{typeid:data.id})
      },
      toyishiting(data){
        this.$router.push('/caseyushutdetails?id='+data.id)
      },
      sosuo(){
          let that = this
        that.$http({
          url: that.baseurl +"/searchRule?keyword="+ that.sousuocenter,
          method: "get",
          headers:{
           "web-token":localStorage.getItem('auto_token')
          },
          data:{}
        })
          .then(res => {
              if(res.data.code==200){
                  // that.$message.success(res.data.msg);
                  // that.suoyoulist()
                  that.datalist = res.data.data;
              }else{
                  that.$message.error(res.data.msg);
                  // that.$router.push('/')
              }
              
          })
          .catch(function(error) {
              //  that.$message.error(res.data.message);
        });
      },
      suoyoulist(){
         let that = this
        that.$http({
          url: that.baseurl +"/getRuleIndex?rule_center_id="+ that.id +'&page='+that.listQuery.PageNo +'&limit='+that.listQuery.PageSize,
          method: "get",
          headers:{
           "web-token":localStorage.getItem('auto_token')
          },
          data:{}
        })
          .then(res => {
              if(res.data.code==200){
                  this.datalist = res.data.data.data;
                  // that.$message.success(res.data.msg);
              }else{
                  that.$message.error(res.data.msg);
                  // that.$router.push('/')
              }
              
          })
          .catch(function(error) {
              //  that.$message.error(res.data.message);
        });
      },
      handleOpen(key, keyPath) {
        console.log(key, keyPath);
      },
      handleClose(key, keyPath) {
        console.log(key, keyPath);
      }
    },
  }

</script>
<style scoped>
  .search{
    width: 100%;
    /* background-color: #F2F5FB ; */
  }
 
  .case-list{
    width: 100%;
     background-color: #fff;
  }
  .case-quanbu-list{
    width: 92%;
    margin-left: 4%;
    margin-right: 4%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #EEEEEE ;
    padding: 25px 0;
    position: relative;
  }
  .quanbu-list-mingzi{
    font-weight: 550;
    font-size: 16px;
    color: #333333;
  }
  .quanbu-list-mingzi::after{
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color:#275AF5;
    opacity: 0.5;
    position: absolute;
    left: -12px;
    top: 35px;
  }
  .riqi-quanbu{
    font-size: 14px;
    color: #999999;
  }
  .qurshengya-list{
    text-align: center;
    margin-top: 260px;
  }
  .weisousuo{
    font-weight: 500;
    font-size: 18px;
    color: #333333;
    padding: 15px 0;
  }
  .jianyixiugai{
    font-weight: 400;
    font-size: 14px;
    color: #999999;
  }
</style>


